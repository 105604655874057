import React, { memo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonV3 } from 'components/ComponentV2';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import { TStudentSociety } from 'lib/models/student-society';
import { TCompany } from 'lib/models/company';
import { OrganizationTypeEnum } from 'lib/models/organization';
import { parseResponse } from 'lib/utils/parser';

import { getCommunityPublicPagePathname, getCompanyPublicPagePathname } from 'utils/urls';
import styles from './OrganizationCard.module.scss';

type OrganizationCardProps = {
  organizationType: OrganizationTypeEnum;
  organisation: TStudentSociety | TCompany;
  onFollow: ({ organizationId }: { organizationId: string }) => void;
  onUnfollow: ({ organizationId, followingId }: { organizationId: string; followingId: string }) => void;
};

type CommunityCardProps = {
  community: TStudentSociety;
  onFollow: OrganizationCardProps['onFollow'];
  onUnfollow: OrganizationCardProps['onUnfollow'];
};

type CompanyCardProps = {
  company: TCompany;
  onFollow: OrganizationCardProps['onFollow'];
  onUnfollow: OrganizationCardProps['onUnfollow'];
};

const CommunityCard: React.FC<CommunityCardProps> = ({ community, onFollow, onUnfollow }) => {
  const [tc] = useTranslation('common');
  const { id: companyId, name: communityName, slug, icon_url, university: universityResponse, subscribed, awaiting_approval } = community;
  const universityName = parseResponse(universityResponse)?.name;
  const publicURL = getCommunityPublicPagePathname(slug);

  const handleFollowToggle = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (subscribed || awaiting_approval) {
        onUnfollow({ organizationId: companyId, followingId: companyId });
      } else {
        onFollow({ organizationId: companyId });
      }
    },
    [subscribed, awaiting_approval, companyId, onFollow, onUnfollow]
  );

  return (
    <Link href={publicURL} target="_blank" underlineOnHover={false}>
      <div className={styles.container}>
        <Avatar className={styles.avatar} icon={icon_url} name={communityName} size="40" />
        <div className={styles.info}>
          <h3 className={styles.title}>{communityName}</h3>
          {universityName && <p className={styles.subtitle}>{universityName}</p>}
        </div>
        <ButtonV3
          size="small"
          isFullWidth
          startIcon={subscribed ? 'icon_check-double' : awaiting_approval ? 'icon_check' : undefined}
          color="secondary"
          onClick={handleFollowToggle}
        >
          {awaiting_approval
            ? tc('similar-organizations.request-sent')
            : subscribed
            ? tc('similar-organizations.member')
            : tc('similar-organizations.join')}
        </ButtonV3>
      </div>
    </Link>
  );
};

const CompanyCard: React.FC<CompanyCardProps> = memo(({ company, onFollow, onUnfollow }) => {
  const [tc] = useTranslation('common');
  const { id: communityId, name: communityName, slug, icon_url, industry: industryResponse, following, public_url } = company;
  const industryName = parseResponse(industryResponse)?.name;
  const followingId = parseResponse(following)?.id;
  const publicURL = getCompanyPublicPagePathname(slug);

  const handleFollowToggle = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (followingId) {
        onUnfollow({ organizationId: communityId, followingId });
      } else {
        onFollow({ organizationId: communityId });
      }
    },
    [followingId, communityId, onFollow, onUnfollow]
  );

  return (
    <Link href={publicURL} target="_blank" underlineOnHover={false}>
      <div className={styles.container}>
        <Avatar className={styles.avatar} icon={icon_url} name={communityName} size="40" />
        <div className={styles.info}>
          <h3 className={styles.title}>{communityName}</h3>
          {industryName && <p className={styles.subtitle}>{industryName}</p>}
        </div>
        <ButtonV3 size="small" isFullWidth color="secondary" startIcon={followingId ? 'icon_check-double' : undefined} onClick={handleFollowToggle}>
          {followingId ? tc('similar-organizations.following') : tc('similar-organizations.follow')}
        </ButtonV3>
      </div>
    </Link>
  );
});

export const OrganizationCard: React.FC<OrganizationCardProps> = memo(
  ({ organisation, organizationType, onFollow, onUnfollow }: OrganizationCardProps) => {
    return organizationType === OrganizationTypeEnum.COMMUNITY ? (
      <CommunityCard community={organisation as TStudentSociety} onFollow={onFollow} onUnfollow={onUnfollow} />
    ) : (
      <CompanyCard company={organisation as TCompany} onFollow={onFollow} onUnfollow={onUnfollow} />
    );
  }
);

OrganizationCard.displayName = 'OrganizationCard';
CommunityCard.displayName = 'CommunityCard';
CompanyCard.displayName = 'CompanyCard';
