import cx from 'classnames';

import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { UserAvatarFields } from 'lib/models/User.model';

import styles from './OrganizationHead.module.scss';

export type OrganizationHeadProps = {
  organizationName: string;
  addressLineContent: React.ReactNode;
  shortDescription: string;
  bannerURL: string;
  organizationAvatar: UserAvatarFields | null;
  className?: string;
};

export const OrganizationHead: React.FC<OrganizationHeadProps> = ({
  organizationName,
  addressLineContent,
  shortDescription,
  bannerURL,
  organizationAvatar,
  className,
  children,
}) => {
  return (
    <section
      className={cx(styles.container, className, {
        [styles['has-cover']]: bannerURL,
      })}
    >
      <div className={styles.top}>
        <HuzzleUserAvatar user={organizationAvatar} size="80" tickSize="xlarge" />
        <div className={styles['name-address']}>
          <h1 className={styles.name}>{organizationName}</h1>
          {addressLineContent && <p className={styles.location}>{addressLineContent}</p>}
        </div>
      </div>
      {shortDescription && <p className={styles.description}>{shortDescription}</p>}
      {addressLineContent && <p className={styles.address}>{addressLineContent}</p>}
      {children}
    </section>
  );
};

OrganizationHead.displayName = 'OrganizationHead';
