import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

import { useOrganizationPageStickyNav } from 'hooks/useOrganizationPageStickyNav/useOrganizationPageStickyNav';
import { useAuth } from 'lib/providers/AuthProvider';
import { OrganizationTypeEnum } from 'lib/models/organization';

import styles from './OrganizationSectionGroup.module.scss';

type OrganizationSectionGroupProps<T> = {
  organizationActions: React.ReactNode;
  tabList: Array<{ id: T; title: string; hidden?: boolean; hash: string; badgeCount?: number }>;
  organizationType: OrganizationTypeEnum;
  showBanner?: boolean;
};

export function OrganizationSectionGroup<T extends string>({
  children,
  tabList,
  organizationActions,
  showBanner,
}: React.PropsWithChildren<OrganizationSectionGroupProps<T>>) {
  const { isCandidate } = useAuth();
  const navContainerRef = useRef<HTMLDivElement | null>(null);
  const tabPanelsRef = useRef<HTMLDivElement | null>(null);
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const { stickied: navbarStickied } = useOrganizationPageStickyNav({ navContainerRef });
  const visibleTabList = useMemo(() => tabList.filter(({ hidden }) => !hidden), [tabList]);
  const tabMap = visibleTabList.reduce((acc, { id }) => {
    acc[id] = { isVisible: false, is100Visible: false };
    return acc;
  }, {} as Record<T, { isVisible: boolean; is100Visible: boolean }>);
  const [tabId, setTabId] = useState<string | null>(null);
  const tabChangeRef = useRef<boolean>(false);
  const [tabPanelElementsVisibility, setTabPanelElementsVisibility] = useState<Record<T, { isVisible: boolean; is100Visible: boolean }>>(tabMap);
  const childList = React.Children.toArray(children);
  const childListLength = childList.length;

  useEffect(() => {
    const tabPanelElement = tabPanelsRef.current;
    if (tabPanelElement === null) return;

    const observerOptions = {
      threshold: 0,
      rootMargin: '-200px 0px 200px 0px',
    };
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        // @ts-ignore
        const { target, isIntersecting } = entry;
        const tabId = target.id;
        setTabPanelElementsVisibility((prevState) => ({
          ...prevState,
          [tabId]: { isVisible: isIntersecting, is100Visible: entry.intersectionRatio },
        }));
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const tabPanelElementChildren = Array.from(tabPanelElement.children);
    tabPanelElementChildren.forEach((child) => observer.observe(child));

    return () => observer.disconnect();
  }, [tabPanelsRef, childListLength]);

  const firstVisibleTabId = useMemo(() => {
    const firstVisibleTab =
      visibleTabList.find(({ id }) => tabPanelElementsVisibility[id]?.isVisible && tabPanelElementsVisibility[id]?.is100Visible) ||
      tabList.find(({ id }) => tabPanelElementsVisibility[id]?.isVisible);
    return firstVisibleTab?.id;
  }, [visibleTabList, tabPanelElementsVisibility]);

  useEffect(() => {
    if (tabChangeRef.current || !firstVisibleTabId) return;

    if (navbarStickied) {
      setTabId(firstVisibleTabId);
    } else {
      setTabId(null);
    }
  }, [firstVisibleTabId, tabChangeRef, navbarStickied]);

  const handleTabChange = useCallback(async (id: string) => {
    try {
      tabChangeRef.current = true;
      setTabId(id);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error(error);
    } finally {
      tabChangeRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (!location.hash) return;
    const tab = location.hash.slice(1);
    const isValidHash = visibleTabList.some(({ id }) => id === tab);
    if (isValidHash) {
      handleTabChange(tab);
    }
  }, [visibleTabList]);

  return (
    <section className={styles.container}>
      {visibleTabList.length > 1 && (
        <div
          className={cx(styles['navigation-container'], {
            [styles['is-stickied']]: navbarStickied,
            [styles['below-banner']]: showBanner,
            [styles['no-global-header']]: isCandidate,
          })}
          ref={navContainerRef}
        >
          <nav className={styles['tab-list']} ref={tabListRef}>
            {visibleTabList.map(({ id, title, hash, badgeCount }, tabIndex) => (
              <a
                key={id}
                className={cx(styles.tab, { [styles.selected]: !tabId && tabIndex === 0 ? true : tabId === id })}
                href={hash}
                onClick={() => handleTabChange(id)}
              >
                {title}
                {typeof badgeCount !== 'undefined' && badgeCount > 0 && <span className={styles['badge-count']}>{badgeCount}</span>}
              </a>
            ))}
          </nav>
          <div className={styles['organization-actions']}>{organizationActions}</div>
        </div>
      )}
      <section className={styles['tab-panels']} ref={tabPanelsRef}>
        {childList.map((child, childIndex) => {
          const childTab = visibleTabList[childIndex];
          if (!childTab) return null;

          return (
            <div className={cx(styles['tab-panel'], { [styles['signin-header']]: !isCandidate })} id={childTab.id} key={childTab.id}>
              {(visibleTabList.length === 1 || childIndex !== 0) && <h3 className={styles.title}>{childTab.title}</h3>}
              {child}
            </div>
          );
        })}
      </section>
    </section>
  );
}

OrganizationSectionGroup.displayName = 'OrganizationSectionGroup';
