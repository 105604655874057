import { useCallback, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import useSWRInfinite from 'swr/infinite';

import { API_ROUTES } from 'lib/api-routes';
import { OpportunitiesResponse, TOpportunity } from 'lib/models/opportunity';
import { getAxiosPaginatedResponseTotal, parseAxiosPaginatedResponse } from 'lib/utils/parser';
import { guestApiInstance } from 'lib/utils/axios';

const EVENTS_PER_PAGE = 5;

export type TOrganization = {
  id: string;
  name: string;
};

export const useOrganizationEventsInternal = ({
  organization,
  initialPublishedEventsResponse,
  pastEventsInitialCount,
  perPageCount,
}: {
  organization: TOrganization;
  initialPublishedEventsResponse: OpportunitiesResponse;
  pastEventsInitialCount: number;
  perPageCount?: number;
}) => {
  const { id: organizationId } = organization;
  const eventsRoute = `${API_ROUTES.TOUCHPOINTS}?touchpointable_type=Event&creatable_for_ids=${organizationId}&with_details=true&sort_by=created_at&sort_order=desc`;
  const publishedEventsRoute = eventsRoute;
  const pastEventsRoute = `${eventsRoute}&past=true`;
  const [showPastEvents, setShowPastEvents] = useState(false);

  const getPublishedEventsKey = (pageIndex: number) => {
    const page = pageIndex + 1;
    return `${publishedEventsRoute}&page=${page}&per_page=${perPageCount || EVENTS_PER_PAGE}`;
  };

  const {
    data: paginatedPublishedEventsResponse,
    isValidating: isPublishedEventsValidating,
    isLoading: isPublishedEventsInitialLoading,
    setSize: setPublishedEventsSize,
  } = useSWRInfinite<AxiosResponse<OpportunitiesResponse>>(getPublishedEventsKey, guestApiInstance.get, {
    fallbackData: [
      {
        data: initialPublishedEventsResponse,
      } as AxiosResponse<OpportunitiesResponse>,
    ],
    revalidateOnFocus: false,
    persistSize: true,
    revalidateFirstPage: false,
  });

  const isPublishedEventsLoading = isPublishedEventsInitialLoading ? false : isPublishedEventsValidating;
  const { publishedEvents, publishedEventsTotalCount } = useMemo(
    () => ({
      publishedEvents: parseAxiosPaginatedResponse<TOpportunity>(paginatedPublishedEventsResponse),
      publishedEventsTotalCount: getAxiosPaginatedResponseTotal(paginatedPublishedEventsResponse),
    }),
    [paginatedPublishedEventsResponse]
  );
  const fetchNextPublishedEventsPage = useCallback(() => setPublishedEventsSize((prevSize) => prevSize + 1), [setPublishedEventsSize]);

  const canFetchPastEvents = showPastEvents || publishedEventsTotalCount === 0;

  const getPastEventsKey = (pageIndex: number) => {
    if (!canFetchPastEvents) return null;
    const page = pageIndex + 1;
    return `${pastEventsRoute}&page=${page}&per_page=${EVENTS_PER_PAGE}`;
  };

  const {
    data: paginatedPastEventsResponse,
    isValidating: isPastEventsLoading,
    setSize: setPastEventsSize,
  } = useSWRInfinite<AxiosResponse<OpportunitiesResponse>>(getPastEventsKey, guestApiInstance.get, {
    revalidateOnFocus: false,
    persistSize: true,
    revalidateFirstPage: false,
  });

  const { pastEvents, pastEventsTotalCount } = useMemo(
    () => ({
      pastEvents: parseAxiosPaginatedResponse<TOpportunity>(paginatedPastEventsResponse),
      pastEventsTotalCount: getAxiosPaginatedResponseTotal(paginatedPastEventsResponse),
    }),
    [paginatedPastEventsResponse]
  );
  const fetchNextPastEventsPage = useCallback(() => setPastEventsSize((prevSize) => prevSize + 1), [setPastEventsSize]);
  const enableShowPastEvents = useCallback(() => setShowPastEvents(true), []);
  const showEventsTab = publishedEventsTotalCount + pastEventsInitialCount > 0;

  return {
    organization,
    publishedEvents,
    publishedEventsTotalCount,
    isPublishedEventsLoading,
    pastEvents,
    pastEventsInitialCount,
    pastEventsTotalCount,
    isPastEventsLoading,
    showEventsTab,
    fetchNextPublishedEventsPage,
    fetchNextPastEventsPage,
    enableShowPastEvents,
    perPageCount,
  };
};

export type UseOrganizationEventsReturnType = ReturnType<typeof useOrganizationEventsInternal>;
