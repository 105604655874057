import cx from 'classnames';

import styles from './ContentSection.module.scss';

export const ContentSection = ({
  className,
  title,
  titleClassName,
  children,
  hideTitle,
}: {
  className?: string;
  title: string;
  titleClassName?: string;
  children: React.ReactNode;
  hideTitle?: boolean;
}) => {
  return (
    <div className={cx(styles['content-section'], className)}>
      {!hideTitle && <h3 className={cx(styles['title'], titleClassName)}>{title}</h3>}
      {children}
    </div>
  );
};
