import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  TOrganization,
  UseOrganizationEventsReturnType,
  useOrganizationEventsInternal,
} from 'hooks/useOrganizationEvents/useOrganizationEventsInternal';
import { OpportunitiesResponse } from 'lib/models/opportunity';

const initialContext: UseOrganizationEventsReturnType = {
  organization: {
    id: '',
    name: '',
  },
  publishedEvents: [],
  publishedEventsTotalCount: 0,
  isPublishedEventsLoading: false,
  pastEvents: [],
  pastEventsTotalCount: 0,
  isPastEventsLoading: false,
  pastEventsInitialCount: 0,
  showEventsTab: false,
  perPageCount: 0,
  enableShowPastEvents: noop,
  fetchNextPublishedEventsPage: () => Promise.resolve(undefined),
  fetchNextPastEventsPage: () => Promise.resolve(undefined),
};

const OrganizationEventsContext =
  createContext<UseOrganizationEventsReturnType>(initialContext);

export const useOrganizationEvents = () =>
  useContext(OrganizationEventsContext);

export const OrganizationEventsProvider: React.FC<{
  organization: TOrganization;
  initialPublishedEventsResponse: OpportunitiesResponse;
  pastEventsInitialCount: number;
  perPageCount?: number;
}> = ({
  children,
  organization,
  initialPublishedEventsResponse,
  pastEventsInitialCount,
  perPageCount,
}) => {
  const value = useOrganizationEventsInternal({
    organization,
    initialPublishedEventsResponse,
    pastEventsInitialCount,
    perPageCount,
  });

  return (
    <OrganizationEventsContext.Provider value={value}>
      {children}
    </OrganizationEventsContext.Provider>
  );
};
