import React, { useRef, useState } from 'react';
import cx from 'classnames';

import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { OrganizationHead, OrganizationHeadProps } from 'components/organization-page';
import HamburgerNavMenu from 'components/candidate/HamburgerNavMenu/HamburgerNavMenu.component';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useAuth } from 'lib/providers/AuthProvider';
import { OrganizationTypeEnum } from 'lib/models/organization';

import styles from './OrganizationPageLayout.module.scss';

type OrganizationPageLayoutProps = OrganizationHeadProps & {
  organizationType: OrganizationTypeEnum;
};

export const OrganizationPageLayout: React.FC<OrganizationPageLayoutProps> = ({
  organizationName,
  shortDescription,
  addressLineContent,
  bannerURL,
  organizationAvatar,
  className,
  children,
}) => {
  const { isCandidate } = useAuth();
  const coverRef = useRef(null);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const childList = React.Children.toArray(children);

  if (childList.length < 3 || childList.length > 4) {
    throw new Error('OrganizationPageLayout component requires 3 children for small and 4 children large desktop');
  }

  const [child1, child2, child3, child4] = childList;

  useVisibleOnIntersect({
    elRef: coverRef,
    threshold: 0,
    onIntersect: () => setIsHeaderFixed(false),
    onHidden: () => setIsHeaderFixed(true),
  });

  const showHeaderBg = !bannerURL || isHeaderFixed;

  return (
    <section className={cx(styles.container, className)}>
      {isCandidate && (
        <header className={cx(styles.header, { [styles['has-bg']]: showHeaderBg })}>
          <HamburgerNavMenu
            iconSize="medium"
            className={styles.hamburger}
            triggerClassName={styles['hamburger-trigger']}
            menuListClassName={styles['hamburger-list']}
          />
          <HuzzleUserAvatar user={organizationAvatar} size="medium" className={styles.avatar} />
          <div className={styles['dummy']}></div>
        </header>
      )}
      {bannerURL && (
        <section className={styles.cover} ref={coverRef}>
          <img src={bannerURL} alt={organizationName} className={styles.banner} />
        </section>
      )}
      <article className={cx(styles.content, { [styles['fixed-header']]: !bannerURL && isCandidate })}>
        <main className={styles.main}>
          <OrganizationHead
            organizationName={organizationName}
            shortDescription={shortDescription}
            addressLineContent={addressLineContent}
            bannerURL={bannerURL}
            organizationAvatar={organizationAvatar}
          >
            {child1}
          </OrganizationHead>
          {child2}
          {child3}
        </main>
        {child4 && <aside className={styles.sidebar}>{child4}</aside>}
      </article>
    </section>
  );
};

OrganizationPageLayout.displayName = 'OrganizationPageLayout';
