import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import { OrganizationOpportunityCard } from 'components/ComponentV2/OrganizationOpportunityCard';
import { useOrganizationEvents } from 'lib/providers/OrganizationEventsProvider';

import styles from './OrganizationEvents.module.scss';

const EndedEventsBanner = () => {
  const [tc] = useTranslation('common');
  const { organization } = useOrganizationEvents();
  const { name: organizationName } = organization;

  return (
    <div className={styles['ended-events-banner']}>
      <Icon iconName="icon_info" size="largeplus" />
      <p>{tc('organization-events.no-live-events-banner', { organizationName })}</p>
    </div>
  );
};

export const OrganizationEvents = () => {
  const [tc] = useTranslation('common');
  const {
    publishedEvents,
    pastEvents,
    publishedEventsTotalCount,
    pastEventsTotalCount,
    pastEventsInitialCount,
    isPublishedEventsLoading,
    isPastEventsLoading,
    enableShowPastEvents,
    fetchNextPastEventsPage,
    fetchNextPublishedEventsPage,
    perPageCount = 0,
  } = useOrganizationEvents();
  const hideShowMoreButton =
    (publishedEventsTotalCount === publishedEvents.length && pastEventsInitialCount === 0) ||
    (pastEventsTotalCount > 0 && pastEventsTotalCount === pastEvents.length);

  const showPagination = useMemo(() => {
    return (
      (publishedEventsTotalCount + pastEventsTotalCount > perPageCount || //more than 5 events
        publishedEventsTotalCount > perPageCount || //more than 5 live events
        (publishedEventsTotalCount === 0 && pastEventsInitialCount > perPageCount)) && //no live events and past events are more than 5
      publishedEventsTotalCount + pastEventsTotalCount !== 0 //Total count should not be zero
    );
  }, [publishedEventsTotalCount, pastEventsTotalCount, pastEventsInitialCount]);

  return (
    <>
      {publishedEvents.map((event) => (
        <OrganizationOpportunityCard
          key={event.id}
          opportunity={event}
          className={styles['opportunity-card']}
          showOrganization={false}
          showOpportunityType={false}
          showMultipleHosts
        />
      ))}
      {publishedEventsTotalCount === 0 && pastEventsTotalCount > 0 && <EndedEventsBanner />}
      {publishedEventsTotalCount > 0 && pastEventsTotalCount > 0 && (
        <div className={styles['past-events-header']}>
          <span className={styles.divider} />
          <p className={styles.title}>{tc('organization-events.past-events')}</p>
          <span className={styles.divider} />
        </div>
      )}
      {pastEvents.map((event) => (
        <OrganizationOpportunityCard
          key={event.id}
          opportunity={event}
          className={styles['opportunity-card']}
          showOrganization={false}
          showOpportunityType={false}
          showMultipleHosts
        />
      ))}
      <div className={styles.pagination}>
        {showPagination ? (
          <p className={styles['showing']}>
            {tc('organization-events.showing', {
              visible: publishedEvents.length + pastEvents.length,
              total: publishedEventsTotalCount + pastEventsTotalCount,
            })}
          </p>
        ) : (
          <></>
        )}
        {!hideShowMoreButton && (
          <ButtonV3
            className={styles['show-more']}
            variant="text"
            color="secondary"
            onClick={
              publishedEventsTotalCount === publishedEvents.length
                ? pastEventsTotalCount === 0
                  ? enableShowPastEvents
                  : fetchNextPastEventsPage
                : fetchNextPublishedEventsPage
            }
            isLoading={publishedEventsTotalCount === publishedEvents.length ? isPastEventsLoading : isPublishedEventsLoading}
          >
            {publishedEventsTotalCount === publishedEvents.length && pastEventsTotalCount === 0
              ? tc('organization-events.show-past-events')
              : tc('organization-events.show-more')}
          </ButtonV3>
        )}
      </div>
    </>
  );
};
