export enum OrganizationTypeEnum {
  COMPANY = 'company',
  COMMUNITY = 'community',
}

export interface BasicOrganizationsResponse {
  data: Array<BasicOrganization>;
  meta: {
    total: number;
  };
}

export interface BasicOrganization {
  created_at: string;
  id: string;
  name: string;
  type: 'Company' | 'StudentSociety';
}
