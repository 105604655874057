import { useEffect, useState } from 'react';

import { useResponsive } from 'hooks/useResponsive';
import { useAuth } from 'lib/providers/AuthProvider';

export const useOrganizationPageStickyNav = (props: { navContainerRef: React.RefObject<HTMLDivElement> }) => {
  const [stickied, setStickied] = useState(false);
  const { sm } = useResponsive();
  const { isCandidate } = useAuth();

  useEffect(() => {
    const { navContainerRef } = props;
    const navContainerEl = navContainerRef.current;

    if (!navContainerEl) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        const stickied = sm && isCandidate ? e.boundingClientRect.top <= 56 : e.intersectionRatio < 1 && e.boundingClientRect.top <= 122;
        setStickied(stickied);
      },
      { threshold: [1] }
    );
    observer.observe(navContainerEl);

    return () => observer.disconnect();
  }, [props, sm, isCandidate]);

  return { stickied };
};
