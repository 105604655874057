import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Link, Icon } from 'components';
import { OrganizationCard } from 'components/organization-page';
import SlideShow from 'components/SlideShow';
import { useResponsive } from 'hooks/useResponsive';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import { TStudentSociety } from 'lib/models/student-society';
import { TCompany } from 'lib/models/company';
import { OrganizationTypeEnum } from 'lib/models/organization';

import styles from './SimilarOrganizations.module.scss';

export interface SimilarOrganizationsProps {
  className?: string;
  organizationType: OrganizationTypeEnum;
  organizations: TStudentSociety[] | TCompany[];
  onFollow: ({ organizationId }: { organizationId: string }) => void;
  onUnfollow: ({ organizationId, followingId }: { organizationId: string; followingId: string }) => void;
}

export const SimilarOrganizations: React.FC<SimilarOrganizationsProps> = memo(
  ({ className = '', organizationType, organizations, onFollow, onUnfollow }) => {
    const screens = useResponsive();
    const [tc] = useTranslation('common');
    const { isCandidate } = useAuth();

    const similarSocietiesCard = useMemo(
      () =>
        organizations.map((organisation) => {
          return (
            <OrganizationCard
              key={organisation.id}
              organizationType={organizationType}
              organisation={organisation}
              onFollow={onFollow}
              onUnfollow={onUnfollow}
            />
          );
        }),
      [organizations, isCandidate, organizationType, onFollow, onUnfollow]
    );

    if (!organizations.length) return null;

    return (
      <section className={cx(styles.container, className)}>
        <div className={styles.head}>
          <p className={styles.title}>
            {organizationType === OrganizationTypeEnum.COMPANY ? tc('similar-organizations.companies') : tc('similar-organizations.communities')}
          </p>
          <Link
            href={organizationType === OrganizationTypeEnum.COMMUNITY ? PAGE_ROUTES.SOCIETIES_SEARCH : PAGE_ROUTES.COMPANIES_SEARCH}
            className={styles['view-all']}
          >
            {tc('similar-organizations.view-all')}
            <Icon iconName="icon_arrow-right-2" size="xxsmallplus" className={styles.icon} />
          </Link>
        </div>
        {screens.sm ? (
          <div className={styles['cards-container']}>{similarSocietiesCard}</div>
        ) : (
          <SlideShow
            slidesPerRow={2}
            partialSlide={0.5}
            gutter={16}
            maxWidth="232px"
            width={232}
            className={styles.slideShow}
            navigationClassName={styles.navigation}
          >
            {similarSocietiesCard}
          </SlideShow>
        )}
      </section>
    );
  }
);

SimilarOrganizations.displayName = 'SimilarOrganizations';
